import React from 'react';

import { Box, Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { FlexBetween, FlexBox } from 'components/flex-box';

type WineRatingsProps = {
  rp?: {
    value: number;
    vintage: string | number;
  };
  ws?: {
    value: number;
    vintage: string | number;
  };
  js?: {
    value: number;
    vintage: string | number;
  };
  etc?: {
    name: string;
    value: number;
    vintage: string | number;
  };
};

const WineRatings: React.FC<WineRatingsProps> = ({ rp, ws, js, etc }: WineRatingsProps) => {
  const ratingArr = React.useMemo(() => {
    const scores: { name: { full: string; abbv: string }; value: number; vintage: string | number }[] = [];

    // Robert Parker
    if (rp?.value) {
      const rpVintage = `${rp.vintage}`.startsWith('19') || `${rp.vintage}`.startsWith('20') ? rp.vintage : 'NV';
      scores.push({ name: { full: 'Robert Parker', abbv: 'RP' }, value: rp.value, vintage: rpVintage });
    }

    // Wine Spectator
    if (ws?.value) {
      const wsVin = `${ws.vintage}`.startsWith('19') || `${ws.vintage}`.startsWith('20') ? ws.vintage : 'NV';
      scores.push({ name: { full: 'Wine Spectator', abbv: 'WS' }, value: ws.value, vintage: wsVin });
    }

    // James Suckling
    if (js?.value) {
      const jsVin = `${js.vintage}`.startsWith('19') || `${js.vintage}`.startsWith('20') ? js.vintage : 'NV';
      scores.push({ name: { full: 'James Suckling', abbv: 'JS' }, value: js.value, vintage: jsVin });
    }

    // ETC
    if (etc?.name && etc?.value) {
      const etcVin =
        // `${etc.vintage}`.startsWith('19') || `${etc.vintage}`.startsWith('20') ? etc.vintage : 'Non-Vintage';
        `${etc.vintage}`.startsWith('19') || `${etc.vintage}`.startsWith('20') ? etc.vintage : 'NV';
      scores.push({ name: { full: etc.name, abbv: etc.name }, value: etc.value, vintage: etcVin });
    }

    return scores;
  }, [rp, ws, js, etc]);

  if (ratingArr.length === 0) return null;

  return (
    <Grid container spacing={1.5}>
      {ratingArr.map((rating, i) => {
        return rating !== null ? (
          <Grid xs={3} key={`wine-expert-ratings-${i}-${new Date().toLocaleDateString()}`}>
            <FlexBetween
              flexDirection="column"
              height="100%"
              border={(theme) => `1px solid ${theme.palette.divider}`}
              borderRadius="8px"
              sx={{ p: '8px 12px' }}
              title={`${rating.name.full}의 평가`}
            >
              <FlexBox alignItems="center" flexGrow={1}>
                <Typography
                  component="span"
                  textAlign="center"
                  fontSize="15px"
                  lineHeight="15px"
                  letterSpacing="-0.3px"
                  aria-label={`평가자/평가기관명: ${rating.name.full}`}
                  sx={{ textTransform: 'capitalize' }}
                >
                  {rating.name.full.toLowerCase()}
                </Typography>
              </FlexBox>
              <Box mt={1} textAlign="center">
                <Typography
                  component="strong"
                  textAlign="center"
                  fontSize="15px"
                  lineHeight="18px"
                  fontWeight="bold"
                  letterSpacing="-0.3px"
                  sx={{ display: 'block' }}
                >
                  {rating.value}점
                </Typography>
                <Typography component="span" textAlign="center" fontSize="13px" letterSpacing="-0.3px" color="#707078">
                  {rating.vintage}
                </Typography>
              </Box>
            </FlexBetween>
          </Grid>
        ) : null;
      })}
    </Grid>
  );
};

export default WineRatings;
