import React from 'react';
import Image from 'next/image';

import { Divider, Stack, Typography } from '@mui/material';
import { FlexBox } from '../flex-box';
import HiddenSpan from '../HiddenSpan';

type TasteRatingRowProps = {
  title: '당도' | '산도' | '바디' | '탄닌' | string;
  score: 1 | 2 | 3 | 4 | 5 | number;
};

const TasteRatingRow: React.FC<TasteRatingRowProps> = ({ title, score }: TasteRatingRowProps) => {
  const scoreItems: any[] = [];

  for (let i = 1; i <= 5; i++) {
    scoreItems.push(
      <Image
        width={30}
        height={10}
        src={`/static/images/${i <= score ? 'ico_taste_on.svg' : 'ico_taste_off.svg'}`}
        alt={i <= score ? 'O' : 'X'}
      />
    );
  }
  // render
  return (
    <FlexBox title={`${title}(${score})`}>
      <Typography component="strong" fontSize="14px" letterSpacing="-0.3px" fontWeight="600" whiteSpace="nowrap">
        {title}
        <HiddenSpan>: {score}</HiddenSpan>
      </Typography>
      <Stack
        direction="row"
        flexGrow={1}
        justifyContent="space-between"
        alignItems="center"
        ml="24px"
        divider={<Divider orientation="vertical" flexItem sx={{ my: '7px !important' }} />}
      >
        {scoreItems.map((item, i) => {
          return <span key={`${title}-icon-${i}`}>{item}</span>;
        })}
      </Stack>
    </FlexBox>
  );
};

export default TasteRatingRow;
